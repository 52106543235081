import Signature from "../img/home/Signature/signature.png";
import { motion } from "framer-motion";

import { Image } from "react-bootstrap";
export default function Footer() {
  return (
    <footer>
      <div className="footer__quote">
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          “In photography there is a reality so subtle <br />
          that it becomes more real than reality.”
        </motion.p>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <Image src={Signature} width={250} />
        </motion.div>
      </div>
      <div className="footer__social">
        <div className="social__text">
          <motion.p
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="m-0"
          >
            © Copyright Photography Theme - Designed by Bluekode Digital
          </motion.p>
        </div>
        <motion.div layout className="social__icon">
          <motion.i
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.5 }}
            className="bi bi-facebook"
          ></motion.i>
          <motion.i
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.7 }}
            className="bi bi-instagram"
          ></motion.i>
          <motion.i
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.9 }}
            className="bi bi-youtube"
          ></motion.i>
          <motion.i
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 1.1 }}
            className="bi bi-twitter-x"
          ></motion.i>
        </motion.div>
      </div>
    </footer>
  );
}

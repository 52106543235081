import { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export default function List() {
  const [search, setSearch] = useState("");
  const [blogItems, setBlogItems] = useState([]);

  useEffect(() => {
    const blogList = [
      {
        category: "YOUTUBE",
        name: "BOOST INSTAGRAM VIEW IN NO TIME",
        description:
          "What if your understanding of reality is completely false? What if you found out that every belief you thought of as a ‘law of life’ was in fact, just that – a belief?...",
        date: "Feb 01, 2024",
        comments: " 18,049",
        src: "https://chroma.themerex.net/wp-content/uploads/2023/06/post7.jpg",
        url:'/boost'
      },
      {
        category: "PHOTOGRAPHY",
        name: "NATURE PHOTOGRAPHY",
        description:
        "When I talk about healing, I am talking about the complete transformation that you can experience in your being and in all areas of your life...",
        date: "Jan 10, 2024",
        comments: "16,704",
        src: "https://chroma.themerex.net/wp-content/uploads/2023/06/post1-1024x787.jpg",
        url:'/boost'
      },
      {
        category: "VIDEOGRAPHY",
        name: "NEW CAMERA LENS FILTER",
        description:
        "I cried and cried and decided I am done with me and the only solution to my problems in my life was to not have a life.But then, something happened...",
        date: "Jan 21, 2024",
        comments: "17,447",
        src: "https://chroma.themerex.net/wp-content/uploads/2023/06/post2-1024x787.jpg",
        url:'/boost'
      },
    ];
    if (search === "" || search.length < 3) {
      setBlogItems(blogList);
    } else {
      const filterBySearch = blogList.filter((item) => {
        return (
          item.category.toLowerCase().includes(search.toLowerCase()) ||
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.description.toLowerCase().includes(search.toLowerCase())
        );
      });
      setBlogItems(filterBySearch);
    }
  }, [search]);

  return (
    <section data-content="blog">
      <Container fluid className="pt-5 bg-success-light">
        <Container className="blog-position">
          <Container className="content">
            {blogItems.map((blog, index) => {
              return (
                <Card key={index}>
                  <Link to={blog.url} className="col-md-4">
                    <motion.div
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{ duration: 1 }}
                    >
                      <Card.Img variant="top" src={blog.src} />
                    </motion.div>
                  </Link>
                  <Card.Body>
                    <motion.div
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{ duration: 1 }}
                    >
                      <Link to={blog.url} className="d-block mb-3">
                        <Card.Text
                          className="bg-secondary d-inline-block px-3 rounded-pill text-white py-1"
                          style={{ fontSize: "x-small" }}
                        >
                          {blog.category}
                        </Card.Text>
                      </Link>
                      <Link
                        to={blog.url}
                        className="d-block mb-2 text-decoration-none"
                      >
                        <Card.Subtitle className="mb-2 text-dark">
                          {blog.name}
                        </Card.Subtitle>
                      </Link>
                      <Card.Text className="text-muted">
                        {blog.description}
                      </Card.Text>
                      <hr className="w-25 border border-dark opacity-100 border-1 border-bottom-0" />
                      <Card.Link
                        className="text-decoration-none text-muted"
                        to={blog.url}
                      >
                        <i className="bi bi-calendar-fill me-1"></i> {blog.date}
                      </Card.Link>
                      {/* <Card.Link
                      className="text-decoration-none text-muted"
                      href="#"
                    >
                      <i className="bi bi-chat-square-text-fill me-1"></i>{" "}
                      {blog.comments} {" "}
                      Comments
                    </Card.Link> */}
                    </motion.div>
                  </Card.Body>
                </Card>
              );
            })}
          </Container>
          <Container className="sidebar bg-light p-4 rounded-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="sidebar-input mb-3"
            >
              <label className="form-label">Search</label>
              <input
                className="form-control me-2"
                type="search"
                placeholder="Search ..."
                aria-label="Search"
                onChange={(e) => setSearch(e.target.value)}
              ></input>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="sidebar-categories mb-3"
            >
              <span className="mb-2 d-block">Categories</span>
              <ul>
                <li onClick={(e) => setSearch("healing")}>Photography</li>
                <li onClick={(e) => setSearch("beliefs")}>Videography</li>
              </ul>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="sidebar-posts mb-3"
            >
              <span className="d-block mb-2">Recent Posts</span>
              <div className="card mb-3 gap-2">
                <div className="row g-0  bg-info p-3 rounded-2 align-items-center">
                  <div className="col-3">
                    <img
                      src="https://chroma.themerex.net/wp-content/uploads/2023/06/post7.jpg"
                      className="img-fluid rounded-2"
                      alt="..."
                    />
                  </div>
                  <div className="col-9">
                    <div className="card-body py-0">
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="card-title mb-0">I am Healed</span>
                        <p className="card-text">
                          <small className="text-body-secondary">
                            Jan 02, 2024
                          </small>
                        </p>
                      </div>
                      <p className="card-text text-muted">
                        When I talk about healing, I am talking about...
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row g-0  bg-info p-3 rounded-2 align-items-center">
                  <div className="col-3">
                    <img
                      src="https://chroma.themerex.net/wp-content/uploads/2023/06/post2-1024x787.jpg"
                      className="img-fluid rounded-2"
                      alt="..."
                    />
                  </div>
                  <div className="col-9">
                    <div className="card-body py-0">
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="card-title mb-0">Thank You</span>
                        <p className="card-text">
                          <small className="text-body-secondary">
                            Jan 02, 2024
                          </small>
                        </p>
                      </div>
                      <p className="card-text text-muted">
                        I cried and cried and decided I am done with me...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="sidebar-tags mb-3"
            >
              <span className="tags-heading mb-2">Tags</span>
              <span className="tags border">Photography</span>
              <span className="tags border">Videography</span>
            </motion.div>
          </Container>
        </Container>
      </Container>
    </section>
  );
}

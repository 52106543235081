import { Card, Container, Image } from "react-bootstrap";
import GalleryImageOne from "../../img/home/Gallery/one.jpg";
import GalleryImageTwo from "../../img/home/Gallery/two.jpg";
import GalleryImageThree from "../../img/home/Gallery/three.jpg";
import GalleryImageFour from "../../img/home/Gallery/four.jpg";
import GalleryImageFive from "../../img/home/Gallery/five.jpg";
import GalleryImageSix from "../../img/home/Gallery/six.jpg";
import { motion } from "framer-motion";
export default function Gallery() {
  return (
    <>
      <section content="home__gallery" className="mt-5">
        <motion.div layout style={{ overflow: "hidden" }}>
          <motion.span
            style={{ position: "relative" }}
            initial={{ opacity: 0, bottom: "-1rem" }}
            whileInView={{ opacity: 1, bottom: 0 }}
            transition={{ duration: 0.3 }}
            className="gallery__subtitle mb-2 py-3"
          >
            OUR GALLERY
          </motion.span>
        </motion.div>
        <motion.h1
          initial={{ opacity: 0, bottom: "-1rem" }}
          whileInView={{ opacity: 1, bottom: 0 }}
          transition={{ duration: 0.3, delay: 0.3 }}
          className="mb-5"
        >
          Creative Photo Projects
        </motion.h1>
        <Container>
          <motion.div layout className="gallery__image--container row">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="col-12 col-md-6 gallery__image--card"
            >
              <div className="image__card">
                <Image src={GalleryImageOne} />
              </div>
              <Card.Title>Male Collection</Card.Title>
              <Card.Footer className="text-muted mt-1">Collections</Card.Footer>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="col-12 col-md-6 gallery__image--card"
            >
              <div className="image__card">
                <Image src={GalleryImageTwo} />
              </div>
              <Card.Title>Male Collection</Card.Title>
              <Card.Footer className="text-muted mt-1">Collections</Card.Footer>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="col-12 col-md-6 gallery__image--card"
            >
              <div className="image__card">
                <Image src={GalleryImageThree} />
              </div>
              <Card.Title>Male Collection</Card.Title>
              <Card.Footer className="text-muted mt-1">Collections</Card.Footer>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="col-12 col-md-6 gallery__image--card"
            >
              <div className="image__card">
                <Image src={GalleryImageFour} />
              </div>
              <Card.Title>Male Collection</Card.Title>
              <Card.Footer className="text-muted mt-1">Collections</Card.Footer>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="col-12 col-md-6 gallery__image--card"
            >
              <div className="image__card">
                <Image src={GalleryImageFive} />
              </div>
              <Card.Title>Male Collection</Card.Title>
              <Card.Footer className="text-muted mt-1">Collections</Card.Footer>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="col-12 col-md-6 gallery__image--card"
            >
              <div className="image__card">
                <Image src={GalleryImageSix} />
              </div>
              <Card.Title>Male Collection</Card.Title>
              <Card.Footer className="text-muted mt-1">Collections</Card.Footer>
            </motion.div>
          </motion.div>
          <motion.p
            style={{ position: "relative" }}
            initial={{ opacity: 0, bottom: -15 }}
            whileInView={{ opacity: 1, bottom: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center mb-5 view--more"
          >
            View All Works
          </motion.p>
        </Container>
      </section>
    </>
  );
}

// import Head from "./store/Head";
import Presets from "./store/Presets";

export default function Store() {
  return (
    <>
      {/* <Head /> */}
      <Presets />
    </>
  );
}

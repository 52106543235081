import { Button, Card, Container } from "react-bootstrap";
import WorkShopImageOne from "../../img/home/Workshop/one.jpg";
import WorkShopImageTwo from "../../img/home/Workshop/two.jpg";
import WorkShopImageThree from "../../img/home/Workshop/one.jpg";
import { motion } from "framer-motion";

export default function Workshops() {
  return (
    <motion.section layout content="home__workshops" className="mt-5 mb-4">
      <Container className="workshop__container">
        <motion.span
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="workshops__subtitle mb-2"
        >
          NEWS
        </motion.span>
        <motion.h3
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
          className="text-center mb-5"
        >
          Workshops
        </motion.h3>
        <Container className="row justify-content-center">
          <Card className="col-12 col-md-6 col-lg-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.3 }}
            >
              <Card.Img src={WorkShopImageOne} />
              <Card.Title className="mt-3">
                Scotland Seabirds & Seascapes June 2024
              </Card.Title>
              <Card.Text className="text-muted">
                Embark on a breathtaking journey around Scotland's stunning east
                coast with our exclusive Seabirds and Seascapes Photography
                Workshop this June.
              </Card.Text>
              <Button className="w-100" variant="outline-dark">
                More Info
              </Button>
            </motion.div>
          </Card>
          <Card className="col-12 col-md-6 col-lg-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.3 }}
            >
              <Card.Img src={WorkShopImageTwo} />
              <Card.Title className="mt-3">
                Scotland Seabirds & Seascapes June 2024
              </Card.Title>
              <Card.Text className="text-muted">
                Embark on a breathtaking journey around Scotland's stunning east
                coast with our exclusive Seabirds and Seascapes Photography
                Workshop this June.
              </Card.Text>
              <Button className="w-100" variant="outline-dark">
                More Info
              </Button>
            </motion.div>
          </Card>
          <Card className="col-12 col-md-6 col-lg-4">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.3 }}
            >
              <Card.Img src={WorkShopImageThree} />
              <Card.Title className="mt-3">
                Scotland Seabirds & Seascapes June 2024
              </Card.Title>
              <Card.Text className="text-muted">
                Embark on a breathtaking journey around Scotland's stunning east
                coast with our exclusive Seabirds and Seascapes Photography
                Workshop this June.
              </Card.Text>
              <Button className="w-100" variant="outline-dark">
                More Info
              </Button>
            </motion.div>
          </Card>
        </Container>
      </Container>
    </motion.section>
  );
}

import { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";

export default function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };
  const handleCollapse = () => {
    setIsNavOpen(false);
    console.log(isNavOpen);
  };
  return (
    <header className={`sticky-top bg-light`}>
      <Container className="logo_container">
        <Link className="logo" to="/">
          Hall of the Wild
        </Link>
      </Container>
      <Container fluid className="header__social d-none d-md-block">
        <ul className="mb-0 p-0">
          <li>
            <i className="bi bi-facebook"></i>
          </li>
          <li>
            <i className="bi bi-instagram"></i>
          </li>
          <li>
            <i className="bi bi-youtube"></i>
          </li>
          <li>
            <i className="bi bi-twitter-x"></i>
          </li>
        </ul>
      </Container>
      <Container className="nav_container">
        <Navbar expand="md" className="pb-3">
          <Navbar.Toggle
            onClick={handleNavToggle}
            className={`ms-auto ${isNavOpen ? "open" : ""}`}
            aria-controls="basic-navbar-nav"
            id="navBar-btn"
          >
            <div className="icon-bar"></div>
            <div className="icon-bar"></div>
            <div className="icon-bar"></div>
          </Navbar.Toggle>
          <Navbar.Collapse
            in={isNavOpen}
            onExited={handleCollapse}
            id="basic-navbar-nav"
            // className={`${isNavOpen ? "h-80dvh" : ""}`}
          >
            <Nav
              className={`justify-content-center w-100 p-0 ${
                isNavOpen ? "h-100dvh" : ""
              }`}
            >
              <Nav.Link
                aria-current="page"
                as={NavLink}
                to="/workshops"
                onClick={handleCollapse}
              >
                Workshops
              </Nav.Link>
              <Nav.Link as={NavLink} to="/store" onClick={handleCollapse}>
                Store
              </Nav.Link>
              <Nav.Link as={NavLink} to="/galleries" onClick={handleCollapse}>
                Galleries
              </Nav.Link>
              <Nav.Link as={NavLink} to="/about" onClick={handleCollapse}>
                About
              </Nav.Link>
              <Nav.Link as={NavLink} to="/contact" onClick={handleCollapse}>
                Contact
              </Nav.Link>
              <Container fluid className="header__social d-md-none">
                <ul className="mb-0 p-0">
                  <li>
                    <i className="bi bi-facebook"></i>
                  </li>
                  <li>
                    <i className="bi bi-instagram"></i>
                  </li>
                  <li>
                    <i className="bi bi-youtube"></i>
                  </li>
                  <li>
                    <i className="bi bi-twitter-x"></i>
                  </li>
                </ul>
              </Container>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  );
}

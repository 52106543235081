import ImageCarousel from "./home/Carousel";
import Gallery from "./home/Gallery";
import Video from "./home/Video";
import Workshops from "./home/Workshops";

export default function Home() {
  return (
    <>
      <ImageCarousel />
      <Gallery />
      <Video />
      <Workshops/>
    </>
  );
}

import { Button, Container, Image } from "react-bootstrap";

export default function StoreDetails() {
  return (
    <section>
      <Container>
        <Container className="row align-items-center my-5">
          <Container className="col-12 col-lg-6">
            <Image
              className="w-100"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/1702324565880-5I27BPHM8L1F477IB1VR/AY7A9793.jpg?format=2500w"
            />
          </Container>
          <Container className="col-12 col-lg-6">
            <Container className="col-lg-11">
              <h1 className="mt-4 mt-lg-0">Winter Preset Pack - 2024</h1>
              <p className="mt-4">
                This includes a bunch of useful presets for fixing things like
                blue snow, under and over exposures, as well as has some great
                winter vibes overall.
              </p>
              <div className="d-flex">
                <Button
                  variant="dark"
                  className="px-5 py-2 mt-3 w-100"
                  size="lg"
                >
                  Call For Pricing
                </Button>
              </div>
            </Container>
          </Container>
        </Container>
      </Container>
    </section>
  );
}

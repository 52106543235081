import { Container, Image } from "react-bootstrap";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export default function Galleries() {
  return (
    <>
      <motion.section
        content="galleries__section"
        className="d-flex justify-content-center align-items-center"
      >
        <Container fluid className="row my-5 m-0 p-0">
          <Link to="/gallerydetail" className="col-12 col-md-6 px-0 ">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="img__container"
            >
              <Image
                className="w-100"
                src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/1701988386396-PGGYAH36Q54XUX7V68JO/_A6A5859-Enhanced-NR.jpg?format=1000w"
              />
              <div className="img__text w-100 h-100">Photography</div>
            </motion.div>
          </Link>
          <Link to="/gallerydetail" className="col-12 col-md-6 px-0 ">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="img__container"
            >
              <Image
                className="w-100"
                src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/1701991381268-FK8K60SZ8XZCBSV55OUQ/236051723_250227183588575_5164394349975546997_n.jpg?format=1000w"
              />
              <div className="img__text w-100 h-100">Videography</div>
            </motion.div>
          </Link>
          <Link to="/gallerydetail" className="col-12 col-md-6 px-0 ">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="img__container"
            >
              <Image
                className="w-100"
                src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/1701992837617-7CG55QELJ9LJXXZB20H4/72268041_2508081402571399_4743809571913465856_n.jpg?format=1000w"
              />
              <div className="img__text w-100 h-100">Nature</div>
            </motion.div>
          </Link>
          <Link to="/gallerydetail" className="col-12 col-md-6 px-0 ">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="img__container"
            >
              <Image
                className="w-100"
                src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/1701990767305-MDIJJHKHDV7DO9FHYEKD/_A6A5905-Enhanced-NR+wide.jpg?format=1000w"
              />
              <div className="img__text w-100 h-100">Youtube</div>
            </motion.div>
          </Link>
        </Container>
      </motion.section>
    </>
  );
}

import { Button, Container, Image, ListGroup } from "react-bootstrap";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export default function BoostInstagram() {
  return (
    <section content="workshop-page">
      <Container className="text-center">
        <span className="mt-5 d-block">
          All-Inclusive Photography Workshop June 2024
        </span>
        <h1 className="display-3 mt-5">BOOST INSTAGRAM VIEW IN NO TIME</h1>
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="text-muted card-text mt-4"
        >
          We have always been highly active people, constantly seeking
          adventure. Our passion for adventures and the outdoors have led us to
          so many amazing places and experiences, and all we have ever wanted to
          do was to be able to share those with the world. At Hall of the Wild,
          we don't just get to share our adventures and experiences with the
          world, we get to share yours as well. We are extremely passionate
          about what we do, and enjoy nothing more than creating inspirational
          visuals to tell stories.
        </motion.p>
        <video
          className="h-100 w-100 mt-5"
          src="https://chroma.themerex.net/wp-content/uploads/2023/06/video-background.mp4"
          autoPlay
          playsInline
          muted
          loop
        ></video>
        <span className="display-6 d-block mt-5">
          Join us for an unforgettable exploration of Scotland's wild beauty,
          where every image tells a story.
        </span>
        <Button
          as={Link}
          to="tel:+91987654321"
          className="mt-4 py-3 px-5"
          variant="dark"
          size="lg"
        >
          Reserve Your Spot Now
        </Button>
        <span className="text-decoration-line-through d-block mt-4">
          $1,000 Deposit Due Now. Balance of $3,299 Due March 18, 2024.
        </span>
        <span className="d-block fw-bold h4 mt-4 mb-0">
          Now on sale 40% OFF!!!
        </span>
        <span className="h5 display-6 d-block mt-4">
          All-Inclusive Photography Tour of Scotland’s East Coast
        </span>
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="text-muted card-text mt-4 mb-0 blockquote"
        >
          Scotland's east coast is more than just a location; it's a journey
          through time and nature. Here, the ancient meets the wild, offering
          photographers a canvas where every shot tells a story. The
          ever-changing light dances over landscapes that have stood the test of
          time, providing dynamic conditions for both beginners and seasoned
          photographers to challenge their skills and capture breathtaking
          images. This region's unique blend of historical landmarks, diverse
          wildlife, and natural beauty makes it a once-in-a-lifetime destination
          for photographers seeking to expand their portfolio with truly
          remarkable and distinctive photographs.
        </motion.p>
        <div className="row mt-5">
          <div className="col-12 col-lg-6">
            <span className="h5 fw-bold text-start d-block">
              An Unforgettable Experience
            </span>
            <ListGroup as="ol" numbered>
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start border-0"
              >
                <div className="ms-2 me-auto text-start">
                  <div className="fw-bold text-start">
                    A Bird Watcher's Paradise:
                  </div>
                  Encounter a variety of seabirds, from puffins to gannets,
                  along Scotland's east coast. This birdlife haven features
                  Razorbills, guillemots, eiders, divers, and more, perfect for
                  natural habitat photography.
                </div>
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start border-0"
              >
                <div className="ms-2 me-auto text-start">
                  <div className="fw-bold text-start">Iconic Seascapes:</div>
                  Discover Aberdeenshire's coastline, a paradise for landscape
                  photographers. Its cliffs, castles, and views offer
                  storybook-like scenes.
                </div>
              </ListGroup.Item>
            </ListGroup>
            <span className="h5 fw-bold text-start d-block mt-3">
              A Unique Photography Opportunity
            </span>
            <ListGroup as="ol" numbered>
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start border-0"
              >
                <div className="ms-2 me-auto text-start">
                  <div className="fw-bold text-start">Wildlife Wonders:</div>
                  Engage in capturing diverse wildlife, from environmental shots
                  of birds in their habitats to detailed portraits and dynamic
                  birds-in-flight.
                </div>
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start border-0"
              >
                <div className="ms-2 me-auto text-start">
                  <div className="fw-bold text-start">Landscape Mastery:</div>
                  Master landscape photography, focusing on composition,
                  lighting, and technical aspects. Explore long exposures,
                  filter use, and editing to enhance your photos.
                </div>
              </ListGroup.Item>
            </ListGroup>
          </div>
          <div className="col-12 col-lg-6">
            <Image
              className="ms-auto"
              height={500}
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/bb38c0a7-3ac3-4bf2-ae19-0af3fe2f6d84/Scotland+WS+map+1.jpg?format=750w"
            />
          </div>
        </div>
      </Container>
      <Container className="pt-5">
        <Container className="row align-items-center my-5">
          <Container className="col-12 col-lg-6">
            <span className="h5 fw-bold">Dates & Pricing</span>
            <ListGroup className="px-3 mb-4" variant="flush">
              <ListGroup.Item className="border-0 d-flex align-items-center">
                <i className="bi bi-dot"></i>June 18-22, 2024
              </ListGroup.Item>
              <ListGroup.Item className="border-0 d-flex align-items-center">
                <i className="bi bi-dot"></i>Price: &nbsp;
                <span className="text-decoration-line-through">$4,299</span>
                &nbsp; (now 40% OFF!!!)
              </ListGroup.Item>
              <ListGroup.Item className="border-0 d-flex align-items-center">
                <i className="bi bi-dot"></i>&nbsp;
                <span className="text-decoration-line-through">$1,000</span>
                &nbsp; non-refundable deposit secures your spot (40% OFF)
              </ListGroup.Item>
              <ListGroup.Item className="d-flex align-items-center">
                <i className="bi bi-dot"></i>Balance due by May 18, 2024
              </ListGroup.Item>
            </ListGroup>
            <span className="h5 fw-bold">What's Included</span>
            <ListGroup className="px-3 mb-3" variant="flush">
              <ListGroup.Item className="border-0 d-flex align-items-center">
                <i className="bi bi-dot"></i>Comprehensive photography
                instruction
              </ListGroup.Item>
              <ListGroup.Item className="d-flex align-items-center">
                <i className="bi bi-dot"></i>Lodging, meals, and transport
                during the workshop
              </ListGroup.Item>
            </ListGroup>
            <span className="h5 fw-bold">Not Included</span>
            <ListGroup className="px-3 mb-3" variant="flush">
              <ListGroup.Item className="border-0 d-flex align-items-center">
                <i className="bi bi-dot"></i>Travel to/from the workshop
                location
              </ListGroup.Item>
              <ListGroup.Item className="d-flex align-items-center">
                <i className="bi bi-dot"></i>Lodging/transportation outside
                workshop dates
              </ListGroup.Item>
            </ListGroup>
            <Container className="d-flex justify-content-center">
              <Button
                as={Link}
                to="tel:+91987654321"
                className="my-4 py-3 px-5"
                variant="dark"
                size="lg"
              >
                Reserve Your Spot Now
              </Button>
            </Container>
          </Container>
          <Container className="col-12 col-lg-6">
            <Image
              className="w-100"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/1701906866569-K27LA6RFPLKHVGHLBT8V/_A6A5141-Enhanced-NR+sm.jpg"
            />
          </Container>
        </Container>
      </Container>
    </section>
  );
}

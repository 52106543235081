// import Head from "./workshop/Head";
import List from "./workshop/List";

export default function Workshop() {
  return (
    <>
      {/* <Head /> */}
      <List/>
    </>
  );
}

import { Container, Image } from "react-bootstrap";
import carouselImageOne from "../../img/home/Carousel/one.jpg";
import carouselImageTwo from "../../img/home/Carousel/two.jpg";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

export default function ImageCarousel() {
  return (
    <section content="home__carousel">
      <Container fluid className="p-0">
        <Slide vertical arrows={false} indicators>
          <Image src={carouselImageOne} />
          <Image src={carouselImageTwo} />
          <Image src={carouselImageOne} />
          <Image src={carouselImageTwo} />
        </Slide>
      </Container>
    </section>
  );
}

import { Container } from "react-bootstrap";
import { motion } from "framer-motion";

export default function Video() {
  return (
    <section content="home__video">
      <video
        className="h-100 w-100"
        src="https://chroma.themerex.net/wp-content/uploads/2023/06/video-background.mp4"
        autoPlay
        playsInline
        muted
        loop
      ></video>
      <Container fluid>
        <motion.span
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="video__subtitle mb-2"
        >
          Photo & video collection
        </motion.span>
        <motion.h2
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          Our Digital Products are <br /> created with love
        </motion.h2>
      </Container>
    </section>
  );
}

import { Container, Image } from "react-bootstrap";

export default function GalleryDetails() {
  return (
    <section>
      <Container fluid className="my-3">
        <div className="d-flex">
          <div className="d-flex flex-column col-6">
            <Image
              className="w-100 p-2"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/50f36021-2cd9-406c-a59b-d68eebd6dd65/_A6A5859-Enhanced-NR.jpg"
            />
            <Image
              className="w-100 p-2"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/5135e9df-73c0-469a-9b0b-a7eabdfeb28f/_A6A0908-Enhanced-NR.jpg"
            />
            <Image
              className="w-100 p-2"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/04e5fcc7-e273-4cc0-8e56-e26086927e27/_A6A6822-Enhanced-NR.jpg"
            />
            <Image
              className="w-100 p-2"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/f5a1bb71-0a5a-4fce-9004-1cb8a7b78ee3/_A6A7783-Enhanced-NR.jpg"
            />
            <Image
              className="w-100 p-2"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/02e9eb81-0e33-45c2-b86c-04d509fa005a/_A6A9411-Enhanced-NR.jpg"
            />
            <Image
              className="w-100 p-2"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/c1a18cf5-82da-4f66-abba-a4ee7a3ee491/_A6A9545-Enhanced-NR.jpg"
            />
            <Image
              className="w-100 p-2"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/ff44917a-9f8f-4708-a8f7-e76f6cf5f4a7/_A6A6321+sm.jpg"
            />
            <Image
              className="w-100 p-2"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/ed2c6ab9-5afb-4476-8f8e-df04dcb47e2b/_A6A0876-Enhanced-NR.jpg"
            />
            <Image
              className="w-100 p-2"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/3ece5f66-e069-4825-8505-59fb651529e0/_A6A7316.jpg"
            />
            <Image
              className="w-100 p-2"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/5f7f4f04-f1d5-4281-bb34-ac3afe3694b8/_A6A4590-Enhanced-NR.jpg"
            />
            <Image
              className="w-100 p-2"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/8fcd599f-ce1f-4ef8-944a-e2d7f39645e9/_A6A4614-Enhanced-NR.jpg"
            />
            <Image
              className="w-100 p-2"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/e3904a27-49ad-4b6a-a742-b631ed51cc73/_A6A5332-Enhanced-NR.jpg"
            />
          </div>
          <div className="d-flex flex-column col-6">
            <Image
              className="w-100 p-2"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/1b49e16b-a23f-4048-a512-13d3600e76fb/_A6A7665-Enhanced-NR+sm.jpg"
            />
            <Image
              className="w-100 p-2"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/ef9d4893-d360-4321-8b6a-a176b72d8794/_A6A6554.jpg"
            />
            <Image
              className="w-100 p-2"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/6f55bf14-954d-4a30-bff1-e6faddc39fcc/_A6A6970+sm.jpg"
            />
            <Image
              className="w-100 p-2"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/34ee2d54-1180-4de5-9ed7-7712e57d695c/_A6A9328-Enhanced-NR.jpg"
            />
            <Image
              className="w-100 p-2"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/25735408-e3db-4a96-90af-abd09afdb8a0/_A6A7025-Enhanced-NR.jpg"
            />
            <Image
              className="w-100 p-2"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/5a33f089-b616-4f11-b27e-3bb4dc413b00/_A6A8137-Enhanced-NR.jpg"
            />
            <Image
              className="w-100 p-2"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/4d3590ae-8caf-47bc-b0f5-292a20b1c1cb/_A6A5597-Enhanced-NR.jpg"
            />
            <Image
              className="w-100 p-2"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/24e58ef5-d5a8-4f62-a9b1-588f81781264/_A6A7378-Enhanced-NR.jpg"
            />
            <Image
              className="w-100 p-2"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/b391a131-b6d0-497c-92e8-824dab1d02c1/_A6A4602-Enhanced-NR.jpg"
            />
            <Image
              className="w-100 p-2"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/cc83966f-582d-4ff7-8453-e560912c09d3/_A6A5106-Enhanced-NR.jpg"
            />
            <Image
              className="w-100 p-2"
              src="https://images.squarespace-cdn.com/content/v1/656f80993c61e904f5888d7b/65a435ea-2991-4d9e-be3f-ab8d6f9dc16e/_A6A5859+sm.jpg"
            />
          </div>
        </div>
      </Container>
    </section>
  );
}

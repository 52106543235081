import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "./styles/main.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./pages/Home";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Workshop from "./pages/Workshop";
import Store from "./pages/Store";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Galleries from "./pages/Galleries";
import BoostInstagram from "./pages/workshop/pages/BoostInstagram";
import StoreDetails from "./pages/StoreDetails";
import GalleryDetails from "./pages/GalleryDetails";

function App() {
  return (
    // <BrowserRouter basename="/studio">
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/workshops" element={<Workshop />} />
        <Route path="/store" element={<Store />} />
        <Route path="/galleries" element={<Galleries />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/boost" element={<BoostInstagram />} />
        <Route path="/storedetail" element={<StoreDetails />} />
        <Route path="/gallerydetail" element={<GalleryDetails />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;

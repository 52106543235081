import { Container, Image } from "react-bootstrap";
import { motion } from "framer-motion";
import AboutBgImages from "../img/about/about.png";
export default function About() {
  return (
    <>
      <Container className="my-5">
        <div className="row pb-4">
          <div className="col-12 col-md-6 d-flex flex-column justify-content-center mt-5">
            <motion.h2
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="fw-bolder text-black"
            >
              About us
            </motion.h2>
            <motion.p
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="text-muted card-text mt-4"
            >
              <span className="fw-bold h5 text-dark">Our Journey &nbsp;</span>
              began with a simple desire: to showcase the splendor of nature
              through the lens of a camera. We believe that every creature, from
              the majestic eagle soaring through the skies to the elusive
              leopard prowling in the shadows, has a story worth telling.
            </motion.p>
            <motion.p
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1.5 }}
              className="text-muted card-text mt-4"
            >
              <span className="fw-bold h5 text-dark">Our Mission &nbsp;</span>is
              to bring you closer to the heartbeats of the wild. We traverse the
              globe, venturing into uncharted territories and hidden enclaves to
              snap photographs that do more than just depict scenes; they
              narrate the silent tales of survival, harmony, and the intricate
              dance of ecosystems.
            </motion.p>
            <motion.p
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1.5 }}
              className="text-muted card-text mt-4"
            >
              <span className="fw-bold h5 text-dark">Our Vision &nbsp;</span>is
              to inspire conservation through our imagery. By presenting the raw
              and real vignettes of nature, we aim to stir a movement of
              awareness and appreciation for the delicate balance that sustains
              life on our planet.
            </motion.p>
            <motion.p
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1.5 }}
              className="text-muted card-text mt-4"
            >
              <span className="fw-bold h5 text-dark">
                Our Collection &nbsp;
              </span>
              is a testament to moments that are as rare as they are
              breathtaking. Each photograph is a freeze-frame of time, a
              one-in-the-universe encounter, immortalized through patience,
              skill, and a bit of serendipity.
            </motion.p>
          </div>
          <motion.div
            className="col-12 col-md-6 mt-5"
            initial={{ opacity: 0, scale: 1.1 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
          >
            <Image
              className="w-100"
              style={{ height: "600px", objectFit: "contain" }}
              src={AboutBgImages}
            />
          </motion.div>
          <motion.div layout className="text-center mt-5">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="d-flex justify-content-center"
            >
              <hr className="w-75 border border-1 border-black opacity-75" />
            </motion.div>
            <motion.p
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="text-muted card-text mt-2"
            >
              <span className="fw-bold text-black d-block mb-2 fw-bolder h2">
                Join Us &nbsp;
              </span>
              <span className="d-block pt-1">
                In this visual odyssey as we celebrate the diversity of life and
                the enduring beauty of Mother Earth.
              </span>
              {/* <br />  */}
              Through our photographs, we invite you to pause, reflect, and
              reconnect with the natural world.
            </motion.p>
            <motion.p
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="text-muted card-text mt-4"
            >
              <span className="fw-bold h5 text-dark">
                One in the Universe Photography &nbsp;
              </span>
              - Where every snapshot is a tribute to life’s
              unparalleled artistry.
            </motion.p>
          </motion.div>
        </div>
      </Container>
    </>
  );
}

import { Card, Container, Image } from "react-bootstrap";
import PresetImageOne from "../../img/home/Presets/one.jpg";
import PresetImageTwo from "../../img/home/Presets/two.jpg";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export default function Presets() {
  return (
    <motion.section layout content="store__preset" className="my-5">
      <Container>
        <div className="row">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="preset__container mb-5 col-12 col-md-6 col-lg-4"
          >
            <Link to="/storedetail">
              <Image className="w-100" src={PresetImageOne} />
            </Link>
            <Card.Title
              as={Link}
              to="/storedetail"
              className="mt-3 d-block h5 text-decoration-none"
            >
              Winter Preset Pack -2024
            </Card.Title>
            <Card.Text
              as={Link}
              to="tel:+919876543210"
              className="d-block mt-2 text-muted text-decoration-none"
            >
              Call for Pricing
            </Card.Text>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="preset__container mb-5 col-12 col-md-6 col-lg-4"
          >
            <Link to="/storedetail">
              <Image className="w-100" src={PresetImageTwo} />
            </Link>
            <Card.Title
              as={Link}
              to="/storedetail"
              className="mt-3 d-block h5 text-decoration-none"
            >
              Winter Preset Pack -2024
            </Card.Title>
            <Card.Text
              as={Link}
              to="tel:+919876543210"
              className="d-block mt-2 text-muted text-decoration-none"
            >
              Call for Pricing
            </Card.Text>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="preset__container mb-5 col-12 col-md-6 col-lg-4"
          >
            <Link to="/storedetail">
              <Image className="w-100" src={PresetImageOne} />
            </Link>
            <Card.Title
              as={Link}
              to="/storedetail"
              className="mt-3 d-block h5 text-decoration-none"
            >
              Winter Preset Pack -2024
            </Card.Title>
            <Card.Text
              as={Link}
              to="tel:+919876543210"
              className="d-block mt-2 text-muted text-decoration-none"
            >
              Call for Pricing
            </Card.Text>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="preset__container mb-5 col-12 col-md-6 col-lg-4"
          >
            <Link to="/storedetail">
              <Image className="w-100" src={PresetImageOne} />
            </Link>
            <Card.Title
              as={Link}
              to="/storedetail"
              className="mt-3 d-block h5 text-decoration-none"
            >
              Winter Preset Pack -2024
            </Card.Title>
            <Card.Text
              as={Link}
              to="tel:+919876543210"
              className="d-block mt-2 text-muted text-decoration-none"
            >
              Call for Pricing
            </Card.Text>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="preset__container mb-5 col-12 col-md-6 col-lg-4"
          >
            <Link to="/storedetail">
              <Image className="w-100" src={PresetImageTwo} />
            </Link>
            <Card.Title
              as={Link}
              to="/storedetail"
              className="mt-3 d-block h5 text-decoration-none"
            >
              Winter Preset Pack -2024
            </Card.Title>
            <Card.Text
              as={Link}
              to="tel:+919876543210"
              className="d-block mt-2 text-muted text-decoration-none"
            >
              Call for Pricing
            </Card.Text>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="preset__container mb-5 col-12 col-md-6 col-lg-4"
          >
            <Link to="/storedetail">
              <Image className="w-100" src={PresetImageOne} />
            </Link>
            <Card.Title
              as={Link}
              to="/storedetail"
              className="mt-3 d-block h5 text-decoration-none"
            >
              Winter Preset Pack -2024
            </Card.Title>
            <Card.Text
              as={Link}
              to="tel:+919876543210"
              className="d-block mt-2 text-muted text-decoration-none"
            >
              Call for Pricing
            </Card.Text>
          </motion.div>
        </div>
      </Container>
    </motion.section>
  );
}

import { Container, Image } from "react-bootstrap";
import { motion } from "framer-motion";
import ContactBgImages from '../img/contact/contact.png'

export default function Contact() {
  return (
    <section className="container-contact">
      <Container className="contact my-5">
        <div className="row pt-5">
          <motion.div layout className="col-12 col-md-6">
            <motion.h1
              initial={{ left: -50, opacity: 0 }}
              whileInView={{ left: 0, opacity: 1 }}
              transition={{ duration: 0.3 }}
              style={{ fontSize: "4rem", position: "relative" }}
              className="fw-bold text-center"
            >
              Let’s Work <br />
              Together
            </motion.h1>
          </motion.div>
          <div className="col-12 col-md-6">
            <div className="d-flex justify-content-between flex-column flex-lg-row">
              <motion.div
                layout
                className="d-flex flex-column justify-content-between "
                // style={{ overflow: "hidden" }}
              >
                <motion.div
                  initial={{ bottom: -10, opacity: 0 }}
                  whileInView={{ bottom: 0, opacity: 1 }}
                  transition={{ duration: 0.4, delay: 0.2 }}
                  style={{ position: "relative" }}
                  className="mt-3"
                >
                  <span className="title">WRITE US</span>
                  <span>brent@hallofthewildstudios.com</span>
                </motion.div>
                <motion.div
                  initial={{ bottom: -10, opacity: 0 }}
                  whileInView={{ bottom: 0, opacity: 1 }}
                  transition={{ duration: 0.4, delay: 0.6 }}
                  style={{ position: "relative" }}
                  className="mt-3"
                >
                  <span className="title">CALL US</span>
                  <span>+987654321</span>
                </motion.div>
              </motion.div>
              <div className="">
                <motion.div
                  initial={{ bottom: -10, opacity: 0 }}
                  whileInView={{ bottom: 0, opacity: 1 }}
                  transition={{ duration: 0.4, delay: 0.4 }}
                  style={{ position: "relative" }}
                  className="mt-3"
                >
                  <span className="title">VISIT US</span>
                  <span>
                    732/21 Second Street, Manchester,
                    <br /> King Street,Kingston United Kingdom
                  </span>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Container className="p-0 image-container" fluid>
        <motion.div layout>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Image
              className="w-100"
              src={ContactBgImages}
            />
          </motion.div>
        </motion.div>
      </Container>
    </section>
  );
}
